import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import SizeSet from "@/types/SizeSet";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface ReportInfo {
  performanceReport: any;
  differenceReport: any;
  deliveryReport: any;
  orderReport: any;
  deletedOrderReport: any;
  lastSixMonthReport:any;
  technicalTrackingReport: any;
}

@Module
export default class ReportModule extends VuexModule implements ReportInfo {
  performanceReport = {} as any;
  differenceReport = {} as any;
  deliveryReport = {} as any;
  orderReport = {} as any;
  deletedOrderReport = {} as any;
  lastSixMonthReport = {} as any;
  technicalTrackingReport = {} as any;
  @Mutation
  [Mutations.SET_PERFORMANCE_REPORT](performanceReport) {
    this.performanceReport = performanceReport;
  }

  @Mutation
  [Mutations.SET_DIFFERENCE_REPORT](differenceReport) {
    this.differenceReport = differenceReport;
  }

  @Mutation
  [Mutations.SET_DELIVERY_REPORT](deliveryReport) {
    this.deliveryReport = deliveryReport;
  }

  @Mutation
  [Mutations.SET_ORDER_REPORT](orderReport) {
    this.orderReport = orderReport;
  }
  @Mutation
  [Mutations.SET_DELETED_ORDER_REPORT](deletedOrderReport) {
    this.deletedOrderReport = deletedOrderReport;
  }

  @Mutation
  [Mutations.SET_MONTH_REPORT](lastSixMonthReport) {
    this.lastSixMonthReport = lastSixMonthReport;
  }

  @Mutation
  [Mutations.SET_TECHNICAL_TRACKING_REPORT](technicalTrackingReport) {
    this.technicalTrackingReport = technicalTrackingReport;
  }

  @Action
  [Actions.LAST_SIX_MONTH_REPORT]() {
    // ?Years="+args.years+"&CustomerIds="+args.customerIds+"&SeasonId="+args.seasonId+"&CurrencyTypeId="+args.currencyTypeId+"
    return ApiService.get("report/last-six-month")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_MONTH_REPORT, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.PERFORMANCE_REPORT_LIST](args) {
    // ?Years="+args.years+"&CustomerIds="+args.customerIds+"&SeasonId="+args.seasonId+"&CurrencyTypeId="+args.currencyTypeId+"
    return ApiService.post("Report/performance", args)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PERFORMANCE_REPORT, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.DIFFERENCE_REPORT_LIST](args) {
    // ?Years="+args.years+"&CustomerIds="+args.customerIds+"&SeasonId="+args.seasonId+"&CurrencyTypeId="+args.currencyTypeId+"
    return ApiService.post("report/difference", args)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DIFFERENCE_REPORT, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.DELIVERY_REPORT_LIST](args) {
    // ?Years="+args.years+"&CustomerIds="+args.customerIds+"&SeasonId="+args.seasonId+"&CurrencyTypeId="+args.currencyTypeId+"
    return ApiService.post("report/delivery", args)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DELIVERY_REPORT, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.ORDER_REPORT_LIST](args) {
    // ?Years="+args.years+"&CustomerIds="+args.customerIds+"&SeasonId="+args.seasonId+"&CurrencyTypeId="+args.currencyTypeId+"
    return ApiService.post("report/order", args)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ORDER_REPORT, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.DELETED_ORDER_REPORT_LIST](args) {
    // ?Years="+args.years+"&CustomerIds="+args.customerIds+"&SeasonId="+args.seasonId+"&CurrencyTypeId="+args.currencyTypeId+"
    return ApiService.post("report/deleted-order", args)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DELETED_ORDER_REPORT, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  ["PERFORMANCE_REPORT_EXPORT"](args) {
    return ApiService.file("report/performance/excel", args)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.TECHNICAL_TRACKING_REPORT_LIST](args) {
    return ApiService.post("report/technical-tracking", args)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_TECHNICAL_TRACKING_REPORT, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  ["TECHNICAL_TRACKING_REPORT_EXPORT"](args) {
    return ApiService.file("report/technical-tracking/excel", args)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
}
