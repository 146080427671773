import { createI18n } from "vue-i18n";

const messages = {
  tr: {
    menu: {
      definitions:"Tanımlamalar",
      brand: "Marka Tanımlama",
      orderType:"Sipariş Tipi Tanımlama",
      shippingType:"Sevkiyat Tipi Tanımlama",
      sizeSet: "Beden Setleri",
      differenceReason: "Fark Nedenleri",
      customer: "Müşteri Tanımlama",
      department: "Departman Tanımlama",
      season: "Sezon Tanımlama",
      supplier: "Üretici Tanımlama",
      employee:"Çalışan Listesi",
      orderManagement: "Sipariş Yönetimi",
      order: "Sipariş Listesi",
      reports:"Raporlar",
      performanceReport: "Performans Raporu",
      orderReport: "Sipariş Raporu",
      deliveryReport: "Sevk Raporu",
      differenceReport:"Fark Raporu",
      deletedOrderReport : "Silinen Sipariş Raporu",
      management: "Yönetim",
      transactionLog: "İşlem Logları",
      technicalTracking : "Teknik Takip Raporu",

    },
    wm: "Depo Yönetimi",
    wmList: "Depo Listesi",
    shelf: "Raf",
    createShelf: "Raf Oluştur",
    dashboard: "Dashboard",
    layoutBuilder: "Layout builder",
    craft: "Crafted",
    pages: "Pages 22",
    profile: "Profile",
    profileOverview: "Overview",
    projects: "Projects",
    campaigns: "Campaigns",
    documents: "Documents",
    connections: "Connections",
    wizards: "Wizards",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Account",
    accountOverview: "Overview",
    settings: "Settings",
    authentication: "Authentication",
    basicFlow: "Basic Flow",
    signIn: "Sign-in",
    signUp: "Sign-up",
    passwordReset: "Password Reset",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Apps",
    chat: "Chat",
    definitions: "Tanımlamalar",
    sizeSets: "Beden Setleri",
    differenceReasons: "Fark Nedenleri",
    customerDefinition: "Müşteri Tanımlama",
    departmentDefinition: "Departman Tanımlama",
    seasonDefinition: "Sezon Tanımlama",
    supplierDefinition: "Üretici Tanımlama",
    accountDefinition: "Kullanıcı Listesi",
    orderManagement: "Sipariş Yönetimi",
    orderList: "Sipariş Listesi",
    reports: "Raporlar",
    performanceReport: "Performans Raporu",
    orderReport: "Sipariş Raporu",
    shippingReport: "Sevk Raporu",
    differenceReport: "Fark Raporu",
    management: "Yönetim",
    transactionLog:"İşlem Logları",
    deletedOrderReport: "Silinen Sipariş Raporu"
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "tr",
  globalInjection: true,
  messages,
});

export default i18n;
