export enum Actions {
  // Ana Aksiyonlar
  LOGIN = "login",
  LOGOUT = "logout",
  FORGOT_PASSWORD = "forgotPassword",
  REGISTER = "register",
  VERIFY_AUTH = "verifyAuth",
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH_ACTION = "verifyAuthAction",
  LOGIN_ACTION = "loginAction",
  LOGOUT_ACTION = "logoutAction",
  REGISTER_ACTION = "registerAction",
  FORGOT_PASSWORD_ACTION = "forgotPasswordAction",
  UPDATE_USER = "updateUser",

  // Modül Aksiyonları
  GET_USERS = "getUsers",
  GET_USER_BY_ID = "getUserById",
  ADD_USER = "addUser",
  DELETE_USER = "deleteUser",
  GET_ROLES = "getRoles",

  // Sezon Modülü
  SEASON_LIST = "getSeasons",
  UPDATE_SEASON = "updateSeason",
  CREATE_SEASON = "createSeason",
  ADD_SEASON = "addSeason",
  REMOVE_SEASON = "removeSeason",
  GET_SEASONS = "getSeasons",
  GET_SEASON_BY_ID = "getSeasonById",

  // Sipariş Tipi Modülü
  ORDERTYPE_LIST = "getOrderTypes",
  ORDERTYPE_SELECT_LIST = "getOrderTypeSelectList",
  GET_ORDERTYPE = "getOrderType",
  UPDATE_ORDERTYPE = "updateOrderType",
  CREATE_ORDERTYPE = "createOrderType",
  ADD_ORDERTYPE = "addorderType",
  REMOVE_ORDERTYPE = "removeorderType",
  GET_ORDER_TYPE_LIST = "getOrderTypeList",
  GET_ORDER_TYPE_BY_ID = "getOrderTypeById",
  GET_ORDER_TYPES = "getOrderTypes",

  // Gönderim Metodu
  SHIPPINGMETHOD_LIST = "getShippingMethods",
  SHIPPINGMETHOD_SELECT_LIST = "getShippingMethodSelectList",
  GET_SHIPPINGMETHOD = "getShippingMethod",
  UPDATE_SHIPPINGMETHOD = "updateShippingMethod",
  CREATE_SHIPPINGMETHOD = "createShippingMethod",
  GET_SHIPPING_METHODS = "getShippingMethods",
  GET_SHIPPING_METHODS_BY_ID = "getShippingMethodsById",
  GET_SHIPPING_TYPES = "getShippingTypes",
  SHIPPINGTYPE_LIST = "shippingTypeList",
  ADD_SHIPPINGTYPE = "addshippingType",
  UPDATE_SHIPPINGTYPE = "updateshippingType",
  REMOVE_SHIPPINGTYPE = "removeshippingType",

  // Departman
  DEPARTMENT_LIST = "departmentList",
  DEPARTMENT_SELECT_LIST = "getDepartmentSelectList",
  GET_DEPARTMENT = "getDepartment",
  UPDATE_DEPARTMENT = "updateDepartment",
  CREATE_DEPARTMENT = "createDepartment",
  ADD_DEPARTMENT = "addDepartment",
  REMOVE_DEPARTMENT = "removeDepartment",
  GET_DEPARTMENTS = "getDepartments",
  GET_MATERIALS = "getMaterials",

  // Marka
  BRAND_LIST = "brandList",
  BRAND_SELECT_LIST = "getBrandSelectList",
  GET_BRAND = "getBrand",
  UPDATE_BRAND = "updateBrand",
  CREATE_BRAND = "createBrand",
  ADD_BRAND = "addBrand",
  REMOVE_BRAND = "removeBrand",
  GET_BRANDS = "getBrands",
  GET_BRAND_BY_ID = "getBrandById",

  // Tedarikçi
  SUPPLIER_LIST = "supplierList",
  SUPPLIER_SELECT_LIST = "getSupplierSelectList",
  GET_SUPPLIER = "getSupplier",
  SAVE_SUPPLIER = "saveSupplier",
  UPDATE_SUPPLIER = "updateSupplier",
  CREATE_SUPPLIER = "createSupplier",
  ADD_SUPPLIER = "addSupplier",
  REMOVE_SUPPLIER = "removeSupplier",
  GET_SUPPLIERS = "getSuppliers",
  GET_SUPPLIER_BY_ID = "getSupplierById",

  // Müşteri
  CUSTOMER_LIST = "getCustomers",
  CUSTOMER_SELECT_LIST = "getCustomerSelectList",
  GET_CUSTOMER = "getCustomer",
  UPDATE_CUSTOMER = "updateCustomer",
  CREATE_CUSTOMER = "createCustomer",
  ADD_CUSTOMER = "addCustomer",
  REMOVE_CUSTOMER = "removeCustomer",
  GET_CUSTOMERS = "getCustomers",
  GET_CUSTOMER_BY_ID = "getCustomerById",

  // Sipariş Modülü
  ORDER_LIST = "getOrderList",
  ORDER_DETAIL = "getOrderDetail",
  SAVE_ORDER = "saveOrder",
  UPDATE_ORDER = "updateOrder",
  DELETE_ORDER = "getOrderDetail",
  ORDER_BASIC_INFO = "getOrderBasicInfo",
  ADD_ORDER_DETAIL = "addOrderDetail",
  UPDATE_ORDER_DETAIL = "updateOrderDetail",
  ADD_ORDER = "addOrder",
  ORDER_COPY = "orderCopy",
  ORDER_DELETE = "ORDER_DELETE",
  REMOVE_ORDER = "removeOrder",
  REMOVE_ORDER_LINE = "removeOrderLine",

  // Teknik Takip
  SAVE_TECHNICAL_TRACKING = "saveTechnicalTracking",
  GET_TECHNICAL_TRACKING_LIST = "getTechnicalTrackingList",
  GET_TECHNICAL_TRACKING_DETAIL = "getTechnicalTrackingDetail",
  DELETE_TECHNICAL_TRACKING = "deleteTechnicalTracking",
  REMOVE_TECHNICAL_TRACKING_ARTICLE = "removeTechnicalTrackingArticle",
  TECHNICAL_TRACKING_REPORT_LIST = "technicalTrackingReportList",

  // Diğer Aksiyonlar
  STATUS_LIST = "getStatusList",
  PROVINCE_LIST = "provinceList",
  MY_PROFILE = "myProfile",
  SIZE_SET_LIST = "sizeSetList",
  ADD_SIZE_SET = "addSizeSet",
  UPDATE_SIZE_SET = "updateSizeSet",
  REMOVE_SIZE_SET = "removeSizeSet",
  REMOVE_SIZE = "removeSize",
  DIFFERENCE_REASON_LIST = "differenceReasonList",
  ADD_DIFFERENCE_REASON = "addDifferenceReason",
  UPDATE_DIFFERENCE_REASON = "updateDifferenceReason",
  REMOVE_DIFFERENCE_REASON = "removeDifferenceReason",
  REMOVE_FILE = "removeFile",
  CURRENCY_LIST = "currencyList",
  GET_CURRENCIES = "getCurrencies",
  GET_PAYMENT_METHODS = "getPaymentMethods",
  
  // Teslimat
  DELIVERY_LIST = "deliveryList",
  DELIVERY_LINE_LIST = "deliveryLineList",
  ADD_DELIVERY = "addDelivery",
  REMOVE_DELIVERY = "removeDelivery",
  UPDATE_DELIVERY = "updateDelivery",
  DELIVERY_LINE_BULK_UPDATE = "deliveryLineBulkUpdate",
  DELIVERY_LINE_SOURCE_ADD = "deliveryLineSourceAdd",
  
  // Raporlar
  PERFORMANCE_REPORT_LIST="performanceReportList",
  LAST_SIX_MONTH_REPORT = "lastSixMonthReports",
  DIFFERENCE_REPORT_LIST= "differenceReportList",
  DELIVERY_REPORT_LIST = "deliveryReportList",
  ORDER_REPORT_LIST = "orderReportList",
  DELETED_ORDER_REPORT_LIST = "deletedOrderReportList",
  GET_REPORT = "getReport",
  
  // Ekranlar ve Roller
  TRANSACTİON_LOG_LIST = "transactionLogList",
  SCREEN_GET_BY_ID = "screenGetById",
  SCREEN_LIST = "screenList",
  SCREEN_LIST_WITHOUT_CHILDREN = "screenListWithoutChildren",
  ACTION_LIST = "actionList",
  ADD_SCREEN = "addScreen",
  ADD_SCREEN_ACTION = "addScreenAction",
  DELETE_SCREEN = "deleteScreen",
  ROLE_LIST = "roleList",
  ROLE_SCREEN_LIST = "roleScreenList",
  ADD_ROLE_SCREEN = "addRoleScreen",
  ADD_ROLE = "addRole",
  
  // Gruplar ve Kullanıcılar
  PUBLIC_GROUPS = "publicGroups",
  ADD_GROUP = "addGroup",
  REMOVE_GROUP = "removeGroup",
  ACCOUNT_LIST = "accountList",
  ACCOUNT_DETAIL = "accountDetail",
  ADD_ACCOUNT = "addAccount",
  UPDATE_ACCOUNT = "updateAccount",
  REMOVE_ACCOUNT = "removeAccount",
  CHANGE_PASSWORD = "changePassword",
  
  // Sipariş Satırları
  ORDER_LINE_LIST = "orderLineList",
  ORDER_LINE_ADD = "orderLineAdd",
  ORDER_LINE_UPDATE = "orderLineUpdate",
  ORDER_LINE_DELETE = "orderLineDelete",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",

  // OrderManager
  ORDERMANAGER_LIST = "orderManagerList",
  ADD_ORDERMANAGER = "addOrderManager", 
  UPDATE_ORDERMANAGER = "updateOrderManager",
  REMOVE_ORDERMANAGER = "removeOrderManager",

  // Yeni eklenen kod
  SEARCH_ARTICLE_BY_CODE = "SEARCH_ARTICLE_BY_CODE",
}

export enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",

  SET_PROVINCE = "setProvince",
  SET_COMPANY = "setCompany",
  SET_SIZE_SET = "setSizeSet",
  SET_DIFFERENCE_REASON = "setDifferenceReason",
  SET_CUSTOMER = "setCustomer",
  SET_DEPARTMENT = "setDepartment",
  SET_SEASON = "setSeason",
  SET_SUPPLIER = "setSupplier",
  SET_ACCOUNT = "setAccount",
  SET_ORDER = "setOrder",
  SET_ORDER_DETAIL = "setOrderDetail",
  SET_CURRENCY = "setCurrency",
  SET_DELIVERY = "setDelivery",
  SET_DELIVERY_LINE="setDeliveryLine",
  SET_PERFORMANCE_REPORT = "setPerformanceReport",
  SET_DIFFERENCE_REPORT = "setDifferenceReport",
  SET_DELIVERY_REPORT = "setDeliveryReport",
  SET_ORDER_REPORT = "setOrderReport",
  SET_DELETED_ORDER_REPORT = "setDeletedOrderReport",
  SET_MONTH_REPORT = "setMonthReport",
  SET_TRANSACTION_LOG="setTransactionLog",
  SET_SCREEN="setScreen",
  SET_SCREEN_WITHOUT_CHILDREN = "setScreemWithoutChildren",
  SET_ACTION = "setAction",
  SET_ROLE = "setRole",
  SET_ROLE_SCREEN = "setRoleScreen",
  SET_BRAND = "setBrand",
  SET_ORDERTYPE = "setOrderType",
  SET_SHIPPINGTYPE = "setShippingType",
  
  // Teknik Takip Mutasyonları
  SET_TECHNICAL_TRACKING_LIST = "setTechnicalTrackingList",
  SET_TECHNICAL_TRACKING_DETAIL = "setTechnicalTrackingDetail",
  SET_TECHNICAL_TRACKING_REPORT = "setTechnicalTrackingReport",

  // OrderManager
  SET_ORDERMANAGER = "setOrderManager",
}
