import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import Order from "@/types/Order";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface OrderInfo {
  order: Order;
  articleSearchResults: any;
}

@Module
export default class OrderModule extends VuexModule implements OrderInfo {
  order = {} as Order;
  articleSearchResults = [] as any[];

  @Mutation
  [Mutations.SET_ORDER](order) {
    this.order = order;
  }

  @Mutation
  ["SET_ARTICLE_SEARCH_RESULTS"](results) {
    this.articleSearchResults = results;
  }

  @Action
  [Actions.ORDER_LIST](model) {
    if (
      window.localStorage.getItem("articleNumber") != null ||
      window.localStorage.getItem("articleNumber") != undefined
    ) {
      model.articleNumber = window.localStorage.getItem("articleNumber");
    }
    return ApiService.post("Order/list", model)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ORDER, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.ORDER_DETAIL](id) {
    return ApiService.get(`Order/${id}/detail`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ORDER, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.ORDER_BASIC_INFO](id) {
    return ApiService.get(`Order/${id}/basic-info`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ORDER, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.ORDER_COPY](id) {
    return ApiService.get("Order/copy?OrderId=" + id + "")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ORDER, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.ORDER_DELETE](args) {
    return ApiService.put("Order", args)
      .then(({ data }) => {
        //this.context.commit(Mutations.SET_ORDER, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.UPDATE_ORDER_DETAIL](args) {
    return ApiService.post("order-detail/update", args)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.ADD_ORDER](args) {
    return ApiService.FilePost("Order", args).then(({ data }) => {
      if (!data.success) {
        return data;
      } else {
        //this.context.commit(Mutations.SET_SIZE_SET, data);
        return data;
      }
    });
  }

  @Action
  [Actions.ADD_ORDER_DETAIL](args) {
    return ApiService.post("order/detail", args).then(({ data }) => {
      if (!data.success) {
        return data;
      } else {
        //this.context.commit(Mutations.SET_SIZE_SET, data);
        return data;
      }
    });
  }

  @Action
  [Actions.UPDATE_ORDER](args) {
    return ApiService.FilePost("order/update", args).then(({ data }) => {
      if (!data.success) {
        return data;
      } else {
        //this.context.commit(Mutations.SET_SIZE_SET, data);
        return data;
      }
    });
  }

  @Action
  [Actions.REMOVE_ORDER](id) {
    return ApiService.delete("Order/" + id + "")
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }
  @Action
  [Actions.REMOVE_ORDER_LINE](id) {
    return ApiService.delete("Order/" + id + "")
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }
  @Action
  [Actions.REMOVE_FILE](id) {
    return ApiService.delete("File/" + id + "")
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }

  @Action
  [Actions.SEARCH_ARTICLE_BY_CODE](articleCode) {
    return ApiService.get(`order/by-article?articleCode=${encodeURIComponent(articleCode)}`)
      .then(({ data }) => {
        console.log("Artikel arama yanıtı:", data);
        
        // Diğer store işlemlerine benzer şekilde işleme
        if (data && data.value) {
          // API yanıtı value alanında geliyor
          this.context.commit("SET_ARTICLE_SEARCH_RESULTS", data.value);
          return { success: true, data: data.value, message: "" };
        } 
        else if (data && Array.isArray(data)) {
          // Doğrudan dizi olarak gelen yanıt
          this.context.commit("SET_ARTICLE_SEARCH_RESULTS", data);
          return { success: true, data: data, message: "" };
        }
        else if (data && typeof data === 'object') {
          // Diğer yanıt formatlarını kontrol et
          const results = data.results || data.data || data.items || data.value || [];
          this.context.commit("SET_ARTICLE_SEARCH_RESULTS", results);
          return { success: true, data: results, message: "" };
        }
        else {
          // Sonuç yoksa boş dizi dön
          this.context.commit("SET_ARTICLE_SEARCH_RESULTS", []);
          return { success: false, data: [], message: "Sonuç bulunamadı" };
        }
      })
      .catch((error) => {
        console.error("Artikel arama hatası:", error);
        this.context.commit("SET_ARTICLE_SEARCH_RESULTS", []);
        return { success: false, message: "Artikel arama sırasında bir hata oluştu", data: [] };
      });
  }
}
