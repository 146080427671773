import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface TechnicalTrackingInfo {
  technicalTrackingList: any;
  technicalTrackingDetail: any;
}

@Module
export default class TechnicalTrackingModule extends VuexModule implements TechnicalTrackingInfo {
  technicalTrackingList = {} as any;
  technicalTrackingDetail = {} as any;

  @Mutation
  [Mutations.SET_TECHNICAL_TRACKING_LIST](technicalTrackingList) {
    this.technicalTrackingList = technicalTrackingList;
  }

  @Mutation
  [Mutations.SET_TECHNICAL_TRACKING_DETAIL](technicalTrackingDetail) {
    this.technicalTrackingDetail = technicalTrackingDetail;
  }

  @Action
  [Actions.GET_TECHNICAL_TRACKING_LIST](orderId) {
    return ApiService.get(`TechnicalTracking/by-order/${orderId}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_TECHNICAL_TRACKING_LIST, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_TECHNICAL_TRACKING_DETAIL](id) {
    return ApiService.get(`TechnicalTracking/${id}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_TECHNICAL_TRACKING_DETAIL, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.SAVE_TECHNICAL_TRACKING](technicalTracking) {
    if (technicalTracking.Id) {
      return ApiService.put(`TechnicalTracking/${technicalTracking.Id}`, technicalTracking)
        .then(({ data }) => {
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        });
    } else {
      return ApiService.post("TechnicalTracking", technicalTracking)
        .then(({ data }) => {
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        });
    }
  }

  @Action
  [Actions.DELETE_TECHNICAL_TRACKING](id) {
    return ApiService.delete(`TechnicalTracking/${id}`)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.REMOVE_TECHNICAL_TRACKING_ARTICLE](articleId: any) {
    return ApiService.delete(`TechnicalTracking/remove-article?articleId=${articleId}`) 
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
} 