import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import ProvinceModule from "@/store/modules/ProvinceModule";
import CompanyModule from "@/store/modules/CompanyModule";
import SizeModule from "@/store/modules/SizeModule";
import DifferenceReasonModule from "@/store/modules/DifferenceReasonModule";
import CustomerModule from "@/store/modules/CustomerModule";
import DepartmentModule from "@/store/modules/DepartmentModule";
import SeasonModule from "@/store/modules/SeasonModule";
import SupplierModule from "@/store/modules/SupplierModule";
import AccountModule from "@/store/modules/AccountModule";
import OrderModule from "@/store/modules/OrderModule";
import CurrencyModule from "@/store/modules/CurrencyModule";
import DeliveryModule from "@/store/modules/DeliveryModule";
import ReportModule from "@/store/modules/ReportModule";
import LogModule from "@/store/modules/LogModule";
import ScreenModule from "@/store/modules/ScreenModule";
import RoleModule from "@/store/modules/RoleModule";
import BrandModule from "./modules/BrandModule";
import OrderTypeModule from "./modules/OrderTypeModule";
import ShippingTypeModule from "./modules/ShippingTypeModule";
import TechnicalTrackingModule from "./modules/TechnicalTrackingModule";
config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    ProvinceModule,
    CompanyModule,
    SizeModule,
    DifferenceReasonModule,
    CustomerModule,
    DepartmentModule,
    SeasonModule,
    SupplierModule,
    AccountModule,
    OrderModule,
    CurrencyModule,
    DeliveryModule,
    ReportModule,
    LogModule,
    ScreenModule,
    RoleModule,
    BrandModule,
    OrderTypeModule,
    ShippingTypeModule,
    TechnicalTrackingModule
  },
});

export default store;
